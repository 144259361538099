import React from 'react';
import { motion } from 'framer-motion';
import { FaMicrophone } from 'react-icons/fa';
import { LuMessagesSquare } from 'react-icons/lu';
import { customBranding } from '../../config/config';
import { useChatbotConfig } from '../../contexts/ChatbotConfigContext';

const AnimatedButtonLayout = React.memo(({ handleTalkClick, handleChatClick }) => {
  const { config } = useChatbotConfig();
  // Check if in expanded window
  const isExpandedWindow = window.opener != null;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const buttonVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { 
        type: "spring",
        stiffness: 300,
        damping: 24,
      },
    },
    hover: { 
      scale: 1.05,
      y: -5,
      transition: { 
        duration: 0.3,
      },
    },
    tap: { scale: 0.98, y: 0 },
  };

  const iconVariants = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.2,
      transition: { duration: 0.2 }
    },
  };

  const textVariants = {
    initial: { x: 0 },
    hover: { 
      x: 5,
      transition: { type: "spring", stiffness: 300 }
    },
  };

  const buttonClasses = `w-full rounded-3xl text-white font-semibold relative overflow-hidden 
    transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 
    ${isExpandedWindow ? 'h-12 text-xl py-1' : 'h-10 text-lg py-2'}`;

  return (
    <div>
      <motion.div
        className={`flex space-x-4 ${isExpandedWindow ? 'justify-center px-4' : ''}`}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div 
          className={isExpandedWindow ? "w-48" : "w-1/2"} 
          variants={buttonVariants}
        >
          <motion.button
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            onClick={handleTalkClick}
            className={buttonClasses}
            style={{ backgroundColor: config.color }}
          >
            <div className="flex items-center justify-center space-x-2">
              <motion.div variants={iconVariants}>
                <FaMicrophone size={isExpandedWindow ? 20 : 20} />
              </motion.div>
              <motion.span variants={textVariants}>Talk</motion.span>
            </div>
          </motion.button>
        </motion.div>
         
        <motion.div 
          className={isExpandedWindow ? "w-48" : "w-1/2"} 
          variants={buttonVariants}
        >
          <motion.button
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
            onClick={handleChatClick}
            className={buttonClasses}
            style={{ backgroundColor: config.color }}
          >
            <div className="flex items-center justify-center space-x-2">
              <motion.div variants={iconVariants}>
                <LuMessagesSquare size={isExpandedWindow ? 20 : 20} />
              </motion.div>
              <motion.span variants={textVariants}>Chat</motion.span>
            </div>
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
});

export default AnimatedButtonLayout;