import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { customBranding } from '../../config/config';

const LoadingSpinner = React.memo(() => {
  const [loadingPhase, setLoadingPhase] = useState(0);
  const loadingTexts = ["Thinking", "Analyzing", "Preparing Response"];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingPhase((prev) => (prev + 1) % loadingTexts.length);
    }, 2000);

    return () => clearTimeout(timer);
  }, [loadingPhase]);

  const dotVariants = {
    animate: (i) => ({
      scale: [0.5, 1, 0.5],
      transition: {
        duration: 0.8,
        repeat: Infinity,
        repeatType: "loop",
        ease: "easeInOut",
        delay: i * 0.2,
      },
    }),
  };

  const textVariants = {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
  };

  return (
    <div className="flex items-center">
      <AnimatePresence mode="wait">
        <motion.span
          key={loadingPhase}
          variants={textVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="mr-2 text-sm font-medium text-gray-800"
        >
          {loadingTexts[loadingPhase]}
        </motion.span>
      </AnimatePresence>
      <div className="flex space-x-1">
        {[0, 1, 2].map((i) => (
          <motion.div
            key={i}
            variants={dotVariants}
            animate="animate"
            custom={i}
            className="w-1 h-1 mt-2 rounded-full"
            style={{ backgroundColor: customBranding.LoadingSpinner }}
          />
        ))}
      </div>
    </div>
  );
});

export default LoadingSpinner;