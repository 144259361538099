import React, { createContext, useState, useContext, useEffect } from 'react';
import { customBranding } from '../config/config';

const ChatbotConfigContext = createContext();

export const ChatbotConfigProvider = ({ children }) => {
  // const [config, setConfig] = useState({
  //   ...customBranding,
  //   name: 'Bolofy',
  //   website: 'https://bolofy.com',
  //   description: ''
  // });

  const [config, setConfig] = useState(() => {
    // Check if we're in an expanded window and have config in URL
    if (window.opener) {
      const urlParams = new URLSearchParams(window.location.search);
      const expandedConfig = urlParams.get('expandedConfig');
      if (expandedConfig) {
        try {
          return JSON.parse(decodeURIComponent(expandedConfig));
        } catch (error) {
          console.error('Error parsing expanded config:', error);
        }
      }
    }
    // Fall back to default config
    return customBranding;
  });

  // useEffect(() => {
  //   const handleConfigUpdate = (event) => {
  //     // Log the received message
  //     console.log('Received message:', event);

  //     // Handle configuration update
  //     if (event.data?.type === 'UPDATE_CONFIG') {
  //       console.log('Updating config with:', event.data.payload);
  //       setConfig(prevConfig => ({
  //         ...prevConfig,
  //         ...event.data.payload,
  //         color: event.data.payload.color || prevConfig.color,
  //         // Update other customBranding properties as needed
  //         customBranding: {
  //           ...prevConfig.customBranding,
  //           color: event.data.payload.color || prevConfig.color,
  //         }
  //       }));
  //     }
  //   };

  //   window.addEventListener('message', handleConfigUpdate);
    
  //   // Cleanup
  //   return () => window.removeEventListener('message', handleConfigUpdate);
  // }, []);


  useEffect(() => {
    const handleConfigUpdate = (event) => {
      if (event.data?.type === 'UPDATE_CONFIG') {
        console.log('Received new config:', event.data.payload);
        setConfig(event.data.payload);
      }
    };

    window.addEventListener('message', handleConfigUpdate);
    return () => window.removeEventListener('message', handleConfigUpdate);
  }, []);

  // Persist config changes to URL in expanded window
  useEffect(() => {
    if (window.opener) {
      const currentUrl = new URL(window.location.href);
      const configParams = encodeURIComponent(JSON.stringify(config));
      currentUrl.searchParams.set('expandedConfig', configParams);
      window.history.replaceState({}, '', currentUrl);
    }
  }, [config]);
  
  return (
    <ChatbotConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ChatbotConfigContext.Provider>
  );
};

export const useChatbotConfig = () => {
  const context = useContext(ChatbotConfigContext);
  if (!context) {
    throw new Error('useChatbotConfig must be used within a ChatbotConfigProvider');
  }
  return context;
};