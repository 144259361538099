// Utility functions for managing chat storage
import { v4 as uuidv4 } from 'uuid';

export const CHAT_HISTORY_KEY = 'chatHistory';
export const CHAT_MESSAGES_KEY = 'chatMessages';

export const createNewChat = () => {
  const chatId =  uuidv4();
  const newChat = {
    id: chatId,
    timestamp: new Date().toISOString(),
    title: `Chat ${new Date().toLocaleDateString()}`,
    lastMessage: '',
    messageCount: 0,
    isNew: true
  };

  // Save to chat history
  const history = getChatHistory();
  const updatedHistory = [newChat, ...history];
  localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedHistory));

  // Initialize empty message array for this chat
  saveChatMessages(chatId, []);
  
  return newChat;
};

export const getChatHistory = () => {
  try {
    const history = localStorage.getItem(CHAT_HISTORY_KEY);
    return history ? JSON.parse(history) : [];
  } catch (error) {
    console.error('Error getting chat history:', error);
    return [];
  }
};

export const getChatMessages = (chatId) => {
  try {
    const allMessages = localStorage.getItem(CHAT_MESSAGES_KEY);
    const messagesObj = allMessages ? JSON.parse(allMessages) : {};
    return messagesObj[chatId] || [];
  } catch (error) {
    console.error('Error getting chat messages:', error);
    return [];
  }
};

export const saveChatMessages = (chatId, messages) => {
  try {
    const allMessages = localStorage.getItem(CHAT_MESSAGES_KEY);
    const messagesObj = allMessages ? JSON.parse(allMessages) : {};
    messagesObj[chatId] = messages;
    localStorage.setItem(CHAT_MESSAGES_KEY, JSON.stringify(messagesObj));

    // Update chat history with last message and count
    updateChatHistory(chatId, {
      lastMessage: messages[messages.length - 1]?.content || '',
      messageCount: messages.length
    });
  } catch (error) {
    console.error('Error saving chat messages:', error);
  }
};

export const updateChatHistory = (chatId, updates) => {
  try {
    const history = getChatHistory();
    const updatedHistory = history.map(chat => 
      chat.id === chatId ? { ...chat, ...updates } : chat
    );
    localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedHistory));
  } catch (error) {
    console.error('Error updating chat history:', error);
  }
};

export const deleteChat = (chatId) => {
  try {
    // Remove from chat history
    const history = getChatHistory();
    const updatedHistory = history.filter(chat => chat.id !== chatId);
    localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(updatedHistory));

    // Remove chat messages
    const allMessages = localStorage.getItem(CHAT_MESSAGES_KEY);
    if (allMessages) {
      const messagesObj = JSON.parse(allMessages);
      delete messagesObj[chatId];
      localStorage.setItem(CHAT_MESSAGES_KEY, JSON.stringify(messagesObj));
    }

    return true;
  } catch (error) {
    console.error('Error deleting chat:', error);
    return false;
  }
};

export const clearAllChats = () => {
  try {
    localStorage.removeItem(CHAT_HISTORY_KEY);
    localStorage.removeItem(CHAT_MESSAGES_KEY);
    return true;
  } catch (error) {
    console.error('Error clearing all chats:', error);
    return false;
  }
};